@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/monkee-mixins/_mixins';
@import 'functions';
@import 'base';
@import 'grid';
@import 'fonts';

html, body {
  position: relative;
  height:100%;
}

body {
  font-family: $body-font;
  font-size: 19px;
  color: $gray;
  margin: 0;
  display:flex;
  flex-direction:column;
  max-height:100vh;
}

h1 {
  margin: 0;
  font-family: $heading-font;
  font-size: 38px;
  font-weight: bold;
  color: $white;

  &.alt {
    color: $blue;
  }

  @media #{$medium-down} {
    font-size: 28px;
  }
}

h5 {
  margin-top: 0;
  font-family: $heading-font;
  font-size: 18px;
  font-weight: bold;
  color: $white;
}

a {
  color: $turquoise;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: darker($turquoise, 0.10);
  }
}

.button {
  display: inline-block;
  padding: 10px 30px;
  font-family: $heading-font;
  font-size: 16px;
  color: $white;
  border: 1px solid $white;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background: $white;
    color: $bluechasm;
    text-decoration: none;
  }

  &.alt {
    padding: 10px 85px;
    font-size: 20px;
    color: $deepblue;
    border: 2px solid $deepblue;
    text-decoration: none;

    @media #{$medium-down} {
      padding: 10px 25px;
    }

    &:hover {
      color: $white;
      background: $deepblue;
      text-decoration: none;
    }
  }
}

.logo-font {
  font-family: $logo-font;
}

header {
  width: 100%;
  background: $bluechasm;

  @media #{$medium-up} {
    & {
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 99;
    }
  }

  .row {
    padding: 17px 0 20px;
    margin-bottom: 0;
  }

  .logo {
    display: block;
    margin-top: 6px;
    color: $white;
    font-size: 26px;
  }

  @media #{$medium-down} {
    .row {
      padding: 10px 0 13px;
    }

    .column.half {
      text-align: center;
    }
  }
}

@media #{$medium-up} {
  .primary {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    color: $white;
    padding-bottom:24px;
    flex: 1 1 100%;
    background: url(/images/background-primary.jpg) 0 0;
    background-size: cover;

    .background {

      position: relative;
      width: 100%;
      z-index: 2;
      padding-top: 149px;
    }

    .row {
      margin-bottom: 0;
    }
    .scrollto-container {
      text-align: center;
      margin: 5em 0 2em 0;
    }

    .scrollto {
      font-size: 0;
      line-height: 0;
      color: #fff;
      bottom: 180px;
      width: 100%;
      text-align: center;

      &:after {
        font-size: 40px;
      }
    }
  }
}

@media #{$medium-down} {
  .primary {
    color: $white;
    padding-bottom:24px;
    flex: 1 1 100%;
    background: url(/images/background-primary.jpg) top center;
    background-size: cover;

    .background {
      background-size: cover;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .row {
      margin-bottom: 0;
    }

    .scrollto {
      display: none;
    }
  }
}

.products-second-row {
  margin-top: 40px;

  @media #{$medium-down} {
    margin-top: 0px;
  }

  .logo-second-row {
    svg {
      height: 75px;
      width: 75px;
    }
  }
}

.product-logos {
  margin-top: 75px;

  @media #{$medium-down} {
    margin-top: 15px;
  }
}

.max-width {
  max-width: 100%;
}

.videorecon-button {
  color: $white !important;
  border: 2px solid $white !important;
  &:hover {
    border: 2px solid $deepblue !important;
  }
}

.videorecon-infographic {
  width: 500px;
  @media (max-width: 768px) {
    width: inherit;
  }
}

.subsection-logo {
  height: 75px;
  width: 75px;
  margin: 0 auto;
  padding-top: 1.5em;
}

.logo-svg {
  padding: 20px 30px;
  margin-bottom: 10px;
  border: 2px solid $white;
  border-radius: 4px;

  .ibmmobilefirst & {
    padding-top: 42px;
    padding-bottom: 42px;
  }
}

a.product-logo {
  color: $white;
  font-size: 18px;
  text-decoration: none;

  &:hover, &:active {
    color: $turquoise;
    text-decoration: none;

    .logo-svg {
      border-color: $turquoise;
    }
    polygon {
      fill: $turquoise !important;
    }
    rect {
      fill: $turquoise !important;
    }
    path {
      fill: $turquoise !important;
    }
  }
}

.secondary {
  position: relative;
  z-index: 1;
  padding: 0;
  .videorecon {
    background: url(/images/video-recon-hero-bg.jpg) no-repeat top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .audioanalytics {
    background: url(/images/cognitive-audio-background-2172x1448.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .audioanalytics-overlay {
      background-color: rgba(255,255,255,.95);
    }
    svg {
      path {
        fill: #17bab5;
      }
      polygon {
        fill: #17bab5;
      }
      rect {
        fill: #17bab5;
      }
    }
  }
  .prototypes {
    background: url(/images/prototypes-background-1440x960.jpg) no-repeat top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .prototypes-overlay {
      background-color: rgba(86,183,180,.75);
    }
    .prototype-title {
      color: #294783;
    }
    svg {
      path {
        fill: $white;
      }
      polygon {
        fill: $white;
      }
      rect {
        fill: $white;
      }
    }
  }

  @media #{$medium-up} {
    & {}
  }

  .row {
    margin-bottom: 0;
    padding: 50px 0;
  }

  a.button.alt {
    margin-top: 30px;

    @media #{$medium-down} {
      margin-top: 15px;
    }
  }

  .social {
    color: $white;
    background: rgba($bluechasm, 0.7);

    a {
      font-size: 0;
      line-height: 0;
      border: 2px solid $white;
      display: inline-block;
      border-radius: 4px;
      color: $white;
      padding: 18px 0;
      min-width: 66px;
      margin-right: 25px;

      &:hover, &:active {
        color: $bluechasm;
        background: $white;
      }
    }
  }
}

.tertiary {
  position: relative;
  z-index: 1;
  padding: 0;
  background: url(/images/background-secondary.jpg) no-repeat top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @media #{$medium-up} {
    & {}
  }

  .row {
    margin-bottom: 0;
    padding: 50px 0;
  }

  a.button.alt {
    margin-top: 60px;

    @media #{$medium-down} {
      margin-top: 30px;
    }
  }

  .social {
    color: $white;
    background: rgba($bluechasm, 0.7);

    a {
      font-size: 0;
      line-height: 0;
      border: 2px solid $white;
      display: inline-block;
      border-radius: 4px;
      color: $white;
      padding: 18px 0;
      min-width: 66px;
      margin-right: 25px;

      &:hover, &:active {
        color: $bluechasm;
        background: $white;
      }
    }
  }
}

footer {
  position: relative;
  z-index: 1;
  background: $bluechasm;

  @media #{$medium-up} {
    & {}
  }

  .row {
    padding: 15px 0;
    margin-bottom: 0;
  }

  .linkback {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

#ibmwatson {
  width: 74px;
  height: 74px;
}

#ibmmobilefirst {
  width: 200px;
  height: 31px;
}

#ibmbluemix {
  width: 75px;
  height: 75px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
