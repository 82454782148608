@import '../node_modules/susy/sass/susy';

$susy: (
  columns: 12,
  container: 1170px,
  column-width: 70px,
  gutter-position: inside-static,
  gutters: 30px/70px
);

.row {
  margin-bottom: $block-margin;
  @include container();
}

.row-flush {
  @include container();
}

.column.full {
  @include span(12 of 12);
  float: none;
}

.column.full-center {
  @include span(8 of 12);
  @include push(2);
  float: none;

  @media #{$medium-down} {
    @include span(12 of 12);
    @include push(0);
  }
}

.column.half {
  @include span(6 of 12);
  @media #{$medium-down} {
    width: 100%;
    margin-bottom: 10px;
  }
}

.column.one-third {
  @include span(4 of 12);
  @media #{$medium-down} {
    @include span(12 of 12);
    width: 100%;
    margin-bottom: 10px;
  }
}
