@font-face {
  font-family: 'icomoon';
  src:url('/fonts/icomoon.eot?-y65daq');
  src:url('/fonts/icomoon.eot?#iefix-y65daq') format('embedded-opentype'),
  url('/fonts/icomoon.woff?-y65daq') format('woff'),
  url('/fonts/icomoon.ttf?-y65daq') format('truetype'),
  url('/fonts/icomoon.svg?-y65daq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:after, [class*=" icon-"]:after {
  font-size: 26px;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tumblr:after {
    content: "\e901";
}

.icon-twitter:after {
  content: "\f099";
}

.icon-facebook:after {
  content: "\f09a";
}

.icon-linkedin:after {
  content: "\f0e1";
}

.icon-arrow-down:after {
  content: "\e600";
}
